<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มสีภายนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มสีภายนอก",
      items: [
        {
          text: "ข้อมูลหลัก",
        },
        {
          text: "ข้อมูลหลักผู้แทนจำหน่าย",
        },
        {
          text: "สีภายนอก",
          href: "/d-exterior-color"
        },
        {
          text: "เพิ่มสีภายนอก",
          active: true,
        },
      ],
      brand: "",

      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      exteriorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: "",
      rowMasterBrands: [],
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
    };
  },
  validations: {
    exteriorCode: {},
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    brand: {
      required,
    },
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {
    this.getMasterBrand();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    getMasterBrand() {
      this.overlayFlag = true;
      //   console.log('Success ');
      useNetw
        .get("api/d-exterior-color/vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowMasterBrands = response.data.data;
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    submitDfi: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-exterior-color/store", {
          colorCode: this.exteriorCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
          brandId: this.brand.brandId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            to: "d-exterior-color/edit",
            params: {
              exteriorColorId: response.data.exteriorColorId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        รหัสสีภายนอก <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสสีภายนอก"
                          v-model="exteriorCode"
                          :class="{
                            'is-invalid': submitform && $v.exteriorCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.exteriorCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.exteriorCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-5">
                      <div class="mb-3 position-relative">
                        ยี่ห้อรถ :
                        <multiselect
                          v-model="brand"
                          label="nameEn"
                          type="search"
                          :options="rowMasterBrands"
                          open-direction="bottom"
                          placeholder="ยี่ห้่อรถ"
                          :class="{
                            'is-invalid': submitform && $v.brand.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.brand.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.brand.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.manageBranch.$error,
                          }"
                          >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                        >
                        <div
                          v-if="submitform && $v.manageBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.manageBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
